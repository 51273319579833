import { ref } from '@vue/composition-api'
import store from '@/store'

export const useShopFiltersSortingAndPagination = () => {
  const filters = ref({
    q: '',
    priceRangeDefined: 'all',
    priceRange: [0, 1000],
    categories: '',
    brands: '',
    gender: '',
    ratings: 0,
    page: 1,
    perPage: 30,
  })

  const filterOptions = {
    priceRangeDefined: [
      { text: 'Tümü', value: 'all' },
      { text: '<= 10', value: '<=10' },
      { text: '10 - 100', value: '10-100' },
      { text: '100 - 500', value: '100-500' },
      { text: '>= 500', value: '>=500' },
    ],
    categories: [
      {text: "", value: ""},
      {text: "Büyük Beden Yelek", value: "Büyük Beden Yelek"},
      {text: "Büyük Beden Hırka", value: "Büyük Beden Hırka"},
      {text: "Eşofman Takımı", value: "Eşofman Takımı"},
      {text: "Büyük Beden Eşofman Takımı", value: "Büyük Beden Eşofman Takımı"},
      {text: "Büyük Beden Etek", value: "Büyük Beden Etek"},
      {text: "Büyük Beden Elbise", value: "Büyük Beden Elbise"},
      {text: "Tunik", value: "Tunik"},
      {text: "Büyük Beden Kazak", value: "Büyük Beden Kazak"},
      {text: "Büyük Beden Tunik", value: "Büyük Beden Tunik"},
      {text: "Büyük Beden Ceket", value: "Büyük Beden Ceket"},
      {text: "Büyük Beden Tayt", value: "Büyük Beden Tayt"},
      {text: "Büyük Beden Bluz", value: "Büyük Beden Bluz"},
      {text: "Kazak", value: "Kazak"},
      {text: "Hırka", value: "Hırka"},
      {text: "Büyük Beden Gömlek", value: "Büyük Beden Gömlek"},
      {text: "Gömlek", value: "Gömlek"},
      {text: "Eşofman Altı", value: "Eşofman Altı"},
      {text: "Panço", value: "Panço"},
      {text: "Bluz", value: "Bluz"},
      
    ],
    gender: [{text:'',value:''},{text:'Kadın',value:'Kadın'},{text:'Erkek',value:'Erkek'},{text:'Unisex',value:'Unisex'},{text:'Çocuk',value:'Çocuk'}],
    brands: [{text:'',value:''},{text:'Tusse',value:'Tusse'}],
    ratings: [
      { rating: 5, count: 212 },
      { rating: 4, count: 160 },
      { rating: 3, count: 176 },
      { rating: 2, count: 291 },
      { rating: 1, count: 190 },
      { rating: 0, count: 288 },
    ],
  }

  // Sorting
  const sortBy = ref({ text: 'Gelişmiş', value: 'featured' })
  const sortByOptions = [
    { text: 'Gelişmiş', value: 'featured' },
    { text: 'Düşük Fiyat', value: 'price-asc' },
    { text: 'Yüksek Fiyat', value: 'price-desc' },
  ]

  return {
    // Filter
    filters,
    filterOptions,

    // Sort
    sortBy,
    sortByOptions,
  }
}

export const useShopUi = () => {
  const itemView = 'grid-view'
  const itemViewOptions = [
    { icon: 'GridIcon', value: 'grid-view' },
    { icon: 'ListIcon', value: 'list-view' },
  ]

  // Pagination count <= required by pagination component
  const totalProducts = ref(null)

  return {
    itemView,
    itemViewOptions,
    totalProducts,
  }
}

export const useShopRemoteData = () => {
  const products = ref([])
  const fetchProducts = (...args) => store.dispatch('app-ecommerce/fetchProducts', ...args)

  return {
    products,
    fetchProducts,
  }
}
