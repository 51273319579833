<template>
  <div class="sidebar-detached sidebar-left">
    <!-- Toggler -->
    <!-- <div customizer d-none d-md-block>
    <b-link
      class="customizer-toggle d-flex align-items-center justify-content-center"
      @click="mqShallShowLeftSidebar=true"
    >
      <feather-icon
        icon="SettingsIcon"
        size="15"
        class="spinner"
      />
    </b-link>
    </div> -->
    <!-- /Toggler -->
    <div class="sidebar">
      <div
        class="sidebar-shop"
        :class="{'show': mqShallShowLeftSidebar}"
      >
        <b-row>
          <b-col cols="12">
            <h6 class="filter-heading d-none d-lg-block">
              {{$t('filtre')}}
            </h6>
          </b-col>
        </b-row>

        <!-- Filters' Card -->
        <b-card>

          <!-- Multi Range -->
          <div class="multi-range-price">
            <h6 class="filter-title mt-0">
              {{$t('secim')}}
            </h6>
            <b-form-radio-group
              v-model="filters.priceRangeDefined"
              class="price-range-defined-radio-group"
              stacked
              :options="filterOptions.priceRangeDefined"
              @change="yenile(filters.ratings)"
            />
          </div>

          <!-- Price Slider -->
          <div class="price-slider">
            <h6 class="filter-title">
              {{$t('fiyataraligi')}}
            </h6>
            <vue-slider
              v-model="filters.priceRange"
              :min="0"
              :max="1000"
              :interval="10"
              :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            />
          </div>

          <!-- Categories -->
          <div class="product-categories">
            <h6 class="filter-title">
              {{$t('cinsiyet')}}
            </h6>
            <b-form-radio-group
              v-model="filters.gender"
              class="categories-radio-group"
              stacked
              :options="filterOptions.gender"
            >
            <!-- <template #first>
              <b-form-radio value="first">Tümü</b-form-radio>
            </template> -->
            </b-form-radio-group>
          </div>

          <!-- Categories -->
          <div class="product-categories">
            <h6 class="filter-title">
              {{$t('kategoriler')}}
            </h6>
            <b-form-radio-group
              v-model="filters.categories"
              class="categories-radio-group"
              stacked
              :options="filterOptions.categories"
            >
            <!-- <template #first>
              <b-form-radio value="first">Tümü</b-form-radio>
            </template> -->
            </b-form-radio-group>
          </div>

          <!-- Brands -->
          <div class="brands">
            <h6 class="filter-title">
              {{$t('markalar')}}
            </h6>
            <b-form-radio-group
              v-model="filters.brands"
              class="brands-radio-group"
              stacked
              :options="filterOptions.brands"
            >
            <!-- <template #first>
              <b-form-radio value="first">Tümü</b-form-radio>
            </template> -->
            </b-form-radio-group>
          </div>

          <!-- Ratings -->
          <div class="ratings">
            <h6 class="filter-title">
              {{$t('puanlar')}}
            </h6>
            <div
              v-for="rating in filterOptions.ratings"
              :key="rating.rating"
              class="ratings-list"
              
              
            >
              <b-link @click="yenile(rating.rating)">
                <div class="d-flex" >
                  <!-- <b-form-rating v-model="rating.rating" :options="filterOptions.ratings" variant="warning" readonly show-clear no-border size="lg"></b-form-rating> -->
                  
                  <feather-icon
                    v-for="star in 5"
                    :key="star"
                    icon="StarIcon"
                    size="17"
                    :class="[{'fill-current': star <= rating.rating}, star <= rating.rating ? 'text-warning' : 'text-muted']"
                    v-model="filters.ratings"
                    stacked
                    :options="filterOptions.ratings"
                    
                   
                  />
                  
                  <!-- <span class="ml-25">&amp; up</span> -->
                </div>
              </b-link>
              <div class="stars-received">
                <span>{{ rating.count }}</span>
              </div>
            </div>
          </div>
          <hr>
          <b-button variant="primary" @click="temizle" style="width:100%" v-if="filters.brands || filters.categories || filters.gender || filters.rating">Temizle</b-button>
        </b-card>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormRadioGroup, BLink, BButton,BFormRating
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'

export default {
  components: {
    BRow,
    BCol,
    BFormRadioGroup,
    BLink,
    BCard,
    BButton,
    BFormRating,

    // 3rd Party
    VueSlider,
  },
 methods :{
   temizle(){

     this.filters.rating = 0
     this.filters.brands =''
     this.filters.gender =''
     this.filters.categories =''
     this.filters.priceRangeDefined ='all'
     this.filters.priceRange=[0,1000]


   },
    yenile(rating){
      //console.log(this.filters.priceRangeDefined)
      this.filters.ratings = rating
      if (this.filters.priceRangeDefined =='all'){
        this.filters.priceRange=[0,1000]
      }else if (this.filters.priceRangeDefined =='<=10'){
        this.filters.priceRange=[0,10]
      }else if (this.filters.priceRangeDefined =='10-100'){
        this.filters.priceRange=[10,100]
      }else if (this.filters.priceRangeDefined =='100-500'){
        this.filters.priceRange=[100,500]
      }else if (this.filters.priceRangeDefined =='>=500'){
        this.filters.priceRange=[500,1000]
      }
      window.scrollTo(0, 0)
    }
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
    ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
</style>
