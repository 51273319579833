<template>
  <div style="height: inherit">

    <!-- ECommerce Header -->
    <section id="ecommerce-header" style="position: relative;overflow: hidden;">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="FilterIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ totalProducts }} {{$t('kayitbulundu')}}
              </div>
            </div>
            <div class="view-options d-flex">

              <!-- Sort Button -->
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="sortBy.text"
                right
                variant="outline-primary"
              >
                <b-dropdown-item
                  v-for="sortOption in sortByOptions"
                  :key="sortOption.value"
                  @click="sortBy=sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="filters.q"
              :placeholder="$t('buradanarayin')"
              class="search-product"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!-- Prodcuts -->
    <section :class="itemView">
      <b-card
        v-for="product in products"
        :key="product.id"
        class="ecommerce-card"
        no-body
        
      >
        <div class="item-img text-center">
          <b-link :to="{ name: 'apps-e-commerce-product-details', params: { slug: slugify(product.name) + '-' + product.id} }">
            <b-img
              :alt="`${product.name}-${product.id}`"
              fluid
              class="card-img-top"
              :src="product.image"
              
            />
          </b-link>
        </div>

        <!-- Product Details -->
        <b-card-body>
          <div class="item-wrapper">
            <div class="item-rating">
              <ul class="unstyled-list list-inline">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item"
                  :class="{'ml-25': star-1}"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="16"
                    :class="[{'fill-current': star <= product.rating}, star <= product.rating ? 'text-warning' : 'text-muted']"
                  />
                </li>
                <!-- <li v-if="product.ratingScore">({{product.ratingScore.totalCount ||''}})</li> -->
              </ul>
            </div>
            <div v-show="product.prices.originalPrice !== product.prices.SellingPrice">
              <h6 class="item-price" style="text-decoration:line-through">
                {{ product.prices.originalPrice }}
              </h6>
            </div>
            <div>
              <h6 class="item-price">
                {{ product.price }}
              </h6>
            </div>
          </div>
          <h6 class="item-name">
            <b-link
              class="text-body"
              :to="{ name: 'apps-e-commerce-product-details', params: { slug: slugify(product.name) + '-' + product.id} }"
            >
              {{ product.name }}
            </b-link>
            <b-card-text class="item-company">
              By <b-link class="ml-25">
                {{ product.brand }}
              </b-link>
            </b-card-text>
          </h6>
          <b-card-text v-show="itemView=='list-view'" class="item-description" v-for="(description,index) in product.contentDescriptions" :key="index"><li>{{description.description}}</li></b-card-text>
          
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price">
                {{ product.price }}
              </h4>
            </div>
          </div>
          <b-button
            variant="light"
            tag="a"
            class="btn-wishlist"
            @click="toggleProductInWishlist(product)"
          >
            <feather-icon
              icon="HeartIcon"
              class="mr-50"
              :class="{'text-danger': product.isInWishlist}"
            />
            <span>{{$t('favori')}}</span>
          </b-button>
          <b-button
            variant="primary"
            tag="a"
            class="btn-cart"
            @click="handleCartActionClick(product)"
          >
            <feather-icon
              icon="ShoppingCartIcon"
              class="mr-50"
            />
            <span>{{ product.isInCart ? $t('viewincart') : $t('addtocart') }}</span>
          </b-button>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-if="totalProducts > 0"
            v-model="filters.page"
            :total-rows="totalProducts"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <shop-left-filter-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem, BFormRadioGroup, BFormRadio, BRow, BCol, BInputGroup, BInputGroupAppend, BFormInput, BCard, BCardBody, BLink, BImg, BCardText, BButton, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ShopLeftFilterSidebar from './ECommerceShopLeftFilterSidebar.vue'
import { useShopFiltersSortingAndPagination, useShopUi, useShopRemoteData } from './useECommerceShop'
import { useEcommerceUi } from '../useEcommerce'
import { useRouter } from '@core/utils/utils'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,

    // SFC
    ShopLeftFilterSidebar,
  },
  created(){
//console.log(this.slugify('deneme şeysi'))
//console.log(this.categories)
//console.log(this.gender)
//console.log(this.brands)
//this.filterOptions.categories = this.categories
//console.log(this.filterOptions.categories)
    
  },
  methods: {
    slugify (text) {
    var trMap = {
        'ş': 's', 'Ş': 'S', 'ı': 'i', 'İ': 'I', 'ç': 'c', 'Ç': 'C', 'ü': 'u',
        'Ü': 'U', 'ö': 'o', 'Ö': 'O', 'ğ': 'g', 'Ğ': 'G'
    };
    for(var key in trMap) {
        text = text.replace(new RegExp('['+key+']','g'), trMap[key]);
    }
    return  text.replace(/[^-a-zA-Z0-9\s]+/ig, '') // remove non-alphanumeric chars
                .replace(/\s/gi, ' ') // convert spaces to dashes
                .replace(/[-]+/gi, '-')
                .replace(/ /g, '-')
                .toLowerCase() // trim repeated dashes
              

    },
toUrl(sAciklama){
      //console.log(e);
      var tmp = sAciklama;
      
        tmp=tmp.replace(/ /g,'-').replace(' ','-').toLowerCase();
        tmp=tmp.replace(/ /g,'-').replace('ş','s');
        tmp=tmp.replace(/ /g,'-').replace('.','');
        tmp= tmp.replace(/ /g,'-').replace(".", "");
        tmp= tmp.replace(/ /g,'-').replace(":", "");
        tmp= tmp.replace(/ /g,'-').replace(";", "");
        tmp= tmp.replace(/ /g,'-').replace(",", "");
        tmp= tmp.replace(/ /g,'-').replace(" ", "-");
        tmp= tmp.replace(/ /g,'-').replace("!", "");
        tmp= tmp.replace(/ /g,'-').replace("(", "");
        tmp= tmp.replace(/ /g,'-').replace(")", "");
        tmp= tmp.replace(/ /g,'-').replace("'", "");
        tmp= tmp.replace(/ /g,'-').replace("ğ", "g");
        tmp= tmp.replace(/Ğ/g,'G').replace("Ğ", "G");
        tmp= tmp.replace(/ı/g,'i').replace("ı", "i");
        tmp= tmp.replace(/I/g,'İ').replace("I", "i");
        tmp= tmp.replace(/ç/g,'c').replace("ç", "c");
        tmp= tmp.replace(/Ç/g,'C').replace("ç", "C");
        tmp= tmp.replace(/ö/g,'o').replace("ö", "o");
        tmp= tmp.replace(/Ö/g,'O').replace("Ö", "O");
        tmp= tmp.replace(/ü/g,'u').replace("ü", "u");
        tmp= tmp.replace(/Ü/g,'U').replace("Ü", "U");
        tmp= tmp.replace(/'/g,'').replace("`", "");
        tmp= tmp.replace(/ /g,'=').replace("=", "-");
        tmp= tmp.replace(/ /g,'&').replace("&", "");
        tmp= tmp.replace(/%/g,'').replace("%", "");
        tmp= tmp.replace(/#/g,'').replace("#", "");
        tmp= tmp.replace(/-/g,'-').replace("<", "");
        tmp= tmp.replace(/ /g,'').replace(">", "");
        tmp= tmp.replace(/ /g,'').replace("*", "");
        tmp= tmp.replace(/ /g,'').replace("?", "");
        tmp= tmp.replace(/ /g,'+').replace("+", "-");
        tmp= tmp.replace(/ /g,'»').replace("»", "-");
        tmp= tmp.replace(/ /gi,'|').replace("|", "-");
        tmp= tmp.replace(/\//g,'-').replace("/", "-");
        tmp= tmp.replace(/ /g,'').replace("^", "");
      
      return tmp
    },
  },
  setup() {
    
    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useShopFiltersSortingAndPagination()

    const { route } = useRouter()
      let sKategori = route.value.params.category ||''
      let sBrand = route.value.params.brand ||''
      //console.log(sKategori)
      //console.log(sBrand)
      filters.value.categories = sKategori.toString()
      filters.value.brands = sBrand.toString()

      //console.log(filterOptions.categories)

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    const {
      itemView, itemViewOptions, totalProducts,
    } = useShopUi()

    const { products, fetchProducts } = useShopRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    //filterOptions.categories =[]
    const categories =filterOptions.categories
    const brands =filterOptions.brands
    const gender = filterOptions.gender
    //filterOptions.categories.push('Büyük Beden')
    //filterOptions.categories.push('Büyük Beden Yelek')
    
    //filterOptions.categories.push({text:'itm.categoryName',value:'itm.categoryName'})
    filterOptions.ratings[0].count= 0;
    filterOptions.ratings[1].count= 0;
    filterOptions.ratings[2].count= 0;
    filterOptions.ratings[3].count= 0;
    filterOptions.ratings[4].count= 0;
    filterOptions.ratings[5].count= 0;
    //console.log(filterOptions)
    
    //filterOptions.categories.push({text:'deme',value:'deme'})

    const fetchShopCategories = () => {
      //console.log(filters);
      fetchProducts({
        q: filters.value.q,
        priceRange: filters.value.priceRange,
        categories: filters.value.categories ||'',
        gender: filters.value.gender ||'',
        rating: filters.value.ratings,
        brand: filters.value.brands ||'',
        sortBy: sortBy.value.value,
        page: filters.value.page,
        perPage: 10000,
      })
        .then((response) => {
          products.value = response.data.products
          totalProducts.value = response.data.total
          products.value.forEach( (itm) =>{
            if (filterOptions.categories.some((item) => item.value === itm.categoryName)==false){
              categories.push({text:itm.categoryName,value:itm.categoryName})
              //filterOptions.categories.push({text:itm.categoryName.toString(),value:itm.categoryName.toString()})
              //console.log(filterOptions.categories)
            }
            if (filterOptions.gender.some((item) => item.value === itm.gender)==false){
              gender.push({text:itm.gender,value:itm.gender})
              //console.log(filterOptions.gender)
            }
            if (filterOptions.brands.some((item) => item.value === itm.brand)==false){
              brands.push({text:itm.brand,value:itm.brand})
              //console.log(filterOptions.brands)
            }
            if(itm.rating===5){
              filterOptions.ratings[0].count=filterOptions.ratings[0].count+1 
            }else if(itm.rating===4){
              filterOptions.ratings[1].count=filterOptions.ratings[1].count+1 
            }else if(itm.rating===3){
              filterOptions.ratings[2].count=filterOptions.ratings[2].count+1 
            }else if(itm.rating===2){
              filterOptions.ratings[3].count=filterOptions.ratings[3].count+1 
            }else if(itm.rating===1){
              filterOptions.ratings[4].count=filterOptions.ratings[4].count+1 
            }
            filterOptions.ratings[5].count=filterOptions.ratings[5].count+1

          })
        })
    }
    fetchShopCategories()

    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = () => {
      //console.log(filters);
      fetchProducts({
        q: filters.value.q,
        priceRange: filters.value.priceRange,
        categories: filters.value.categories ||'',
        gender: filters.value.gender ||'',
        rating: filters.value.ratings,
        brand: filters.value.brands ||'',
        sortBy: sortBy.value.value,
        page: filters.value.page,
        perPage: filters.value.perPage,
      })
        .then(response => {
          products.value = response.data.products
          totalProducts.value = response.data.total
          })
    }

    fetchShopProducts()

    watch([filters, sortBy], () => {
      window.scrollTo(0, 0)
     fetchShopProducts()
    }, {
      deep: true,
    })

    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,

      // useShopRemoteData
      products,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
      categories,
      brands,
      gender,
      
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
